import React, {forwardRef, useEffect} from 'react';


const YandexAd = (props) => {
    const divID = `yandex_rtb_R-A-4557586-` + props.id;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://yandex.ru/ads/system/context.js';
        script.async = true;
        document.body.appendChild(script);


        script.onload = () => {
            window.yaContextCb.push(() => {
                window.Ya.Context.AdvManager.render({
                    blockId: `R-A-4557586-${props.id}`,
                    renderTo: divID,
                });
            });
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    return <div id={divID}></div>;
};

export default YandexAd;
