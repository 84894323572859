import React, {useState, useEffect, useRef} from 'react';

import News from './News';
import favoriteStar from './assets/star-icon-favorite.svg';
import star from './assets/star-icon.svg';

import Quote from './Quote';

import {fetchQuotes, fetchNewsAndQuotes} from './utils/fetchData';

import pureSticky from './utils/pure-sticky';

import {useWindowSize} from './hooks/windowSize';
import YandexAd from "./YandexAd";

let lastClosest = '';

const mobileCheck = () => {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

function compare(a, b) {
    const paperA = a.name.toUpperCase();
    const paperB = b.name.toUpperCase();

    let comparison = 0;
    if (paperA > paperB) {
        comparison = 1;
    } else if (paperA < paperB) {
        comparison = -1;
    }
    return comparison;
}

// storage for functions
const anchorObject = {};

// @hack sticky hack and remove this on >=568
window.addEventListener('DOMContentLoaded', function () {
    anchorObject.fixedStickyAnchor1 = pureSticky('#fixed0', 0);
    anchorObject.fixedStickyAnchor2 = pureSticky('#fixed1', 35);
});

const Main = ({getSapeLinks}) => {
    const size = useWindowSize();

    if (size.width >= 568) {
        window.removeEventListener('scroll', anchorObject.fixedStickyAnchor1, true);
        window.removeEventListener('scroll', anchorObject.fixedStickyAnchor2, true);
    }

    // Dont ever think to kill my layout google ads!!!
    useEffect(() => {
        if (size.width >= 568) {
            let tableNodes = document.querySelectorAll('.kek > *');
            Array.from(tableNodes).forEach((node) => {
                if (
                    node.className.includes('google-auto-placed') ||
                    !node.className.includes('table__markets')
                ) {
                    node.remove();
                }
            });
        }
    }, [size.width]);

    const refContainer = useRef(null);

    const [data, setData] = useState();
    useEffect(() => {
        const fetchInfo = () => {
            let data;
            fetchQuotes(
                (d) => {
                    data = d;
                    try {
                        let parsedData = JSON.parse(data);
                        Object.keys(parsedData).forEach((market) =>
                            parsedData[market].sort(compare)
                        );
                        setData(parsedData);
                        let favorite = JSON.parse(localStorage.getItem('favoriteObject'));

                        if (
                            favorite &&
                            Object.keys(favorite).length !== 0 &&
                            Object.keys(parsedData).length > 0
                        ) {
                            let favoriteObjectKek = {};
                            Object.keys(favorite).forEach((market) => {
                                let marketArr = [];
                                favorite[market].forEach((paperId) => {
                                    if (parsedData[market]) {
                                        parsedData[market].forEach((paper) => {
                                            if (paperId === paper.strId) marketArr.push(paper);
                                        });
                                        marketArr.sort(compare);
                                    }
                                });
                                favoriteObjectKek = {
                                    ...favoriteObjectKek,
                                    [market]: marketArr,
                                };
                            });
                            setFavoriteItemsObject(favoriteObjectKek);
                        }
                    } catch (err) {
                        console.log({err});
                    }
                },
                () => {
                    // raise popup
                }
            );
        };

        fetchInfo();

        setTimeout(function runQuotes() {
            fetchInfo();
            setTimeout(runQuotes, 5000);
        }, 5000);
    }, []);
    const [rss, setRss] = useState();
    useEffect(() => {
        const fetchRss = () => {
            let news;
            fetchNewsAndQuotes(
                (d) => {
                    news = d;
                    getSapeLinks();
                    setRss(news);
                },
                () => {
                    // raise popup
                }
            );
        };
        fetchRss();
        setTimeout(function runRss() {
            fetchRss();
            setTimeout(runRss, 60000);
        }, 60000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let optionsStorage = JSON.parse(localStorage.getItem('options')) || {
        favorite: false,
        date: true,
        maxmin: true,
        graphs: true,
    };
    const [options, setOptions] = useState(optionsStorage);
    useEffect(() => {
        localStorage.setItem('options', JSON.stringify(options));
    }, [options]);

    let tablesStorage = JSON.parse(localStorage.getItem('tablesStorage')) || [
        'Currencies',
        'Индексы',
        'Индексы (Фьючерсы)',
        'Энергоресурсы',
        'Металлы',
    ];
    const [tablesToShow, setTablesToShow] = useState(tablesStorage);

    useEffect(() => {
        localStorage.setItem('tablesStorage', JSON.stringify(tablesToShow));
    }, [tablesToShow]);

    //Favorite Object for fast Including/Excluding
    let favoriteStorage =
        JSON.parse(localStorage.getItem('favoriteObject')) || {};
    const [favoriteObject, setFavoriteObject] = useState(favoriteStorage);

    //Favorite Items Object for rendering
    let favoriteItemsStorage =
        JSON.parse(localStorage.getItem('favoriteItemsObject')) || {};
    const [favoriteItemsObject, setFavoriteItemsObject] =
        useState(favoriteItemsStorage);

    const favoriteHandler = (market, item, id) => {
        if (favoriteObject[market] === undefined) {
            setFavoriteObject({...favoriteObject, [market]: [id]});
            setFavoriteItemsObject({
                ...favoriteItemsObject,
                [market]: [item],
            });
        } else if (favoriteObject[market].includes(id)) {
            let filterFavorites = favoriteObject[market].filter((val) => val !== id);
            let filterFavoritesItems = favoriteItemsObject[market].filter(
                (val) => val.strId !== id
            );
            if (filterFavorites.length === 0) {
                delete favoriteObject[market];
                delete favoriteItemsObject[market];
                setFavoriteObject({...favoriteObject});
                setFavoriteItemsObject({...favoriteItemsObject});
            } else {
                setFavoriteObject({
                    ...favoriteObject,
                    [market]: filterFavorites,
                });
                setFavoriteItemsObject({
                    ...favoriteItemsObject,
                    [market]: filterFavoritesItems,
                });
            }
        } else {
            setFavoriteObject({
                ...favoriteObject,
                [market]: [...favoriteObject[market], id],
            });
            setFavoriteItemsObject({
                ...favoriteItemsObject,
                [market]: [...favoriteItemsObject[market], item],
            });
        }
    };
    useEffect(() => {
        localStorage.setItem('favoriteObject', JSON.stringify(favoriteObject));
    }, [favoriteObject]);

    useEffect(() => {
        localStorage.setItem(
            'favoriteItemsObject',
            JSON.stringify(favoriteItemsObject)
        );
    }, [favoriteItemsObject]);
    const FavoriteButtonHandler = () => {
        setOptions({...options, favorite: !options.favorite});
        refContainer.current.scrollIntoView({behavior: 'smooth'});
    };

    let showCurrencyStorage =
        JSON.parse(localStorage.getItem('showCurrencyObject')) || {};
    const [showCurrency, setShowCurrency] = useState(showCurrencyStorage);

    const ChangeCurrencyToRubles = (item) => {
        setShowCurrency({
            ...showCurrency,
            [item]: true,
        });
    };
    const ChangeCurrencyToUsd = (item) => {
        setShowCurrency({
            ...showCurrency,
            [item]: false,
        });
    };

    useEffect(() => {
        localStorage.setItem('showCurrencyObject', JSON.stringify(showCurrency));
    }, [showCurrency]);

    const [dataHelperFavorite, setDataHelperFavorite] = useState([]);
    useEffect(() => {
        if (favoriteItemsObject)
            setDataHelperFavorite([
                0,
                Object.keys(favoriteItemsObject).length / 2,
                Object.keys(favoriteItemsObject).length,
            ]);
    }, [favoriteItemsObject]);

    const [balancedData, setBalancedData] = useState();

    useEffect(() => {
        lastClosest = '';
    }, [tablesToShow]);

    // Rebalancing tables
    useEffect(() => {
        const isBalancedDataRepeat = (balancedData, newBalancedData) => {
            const balancedJSON = JSON.stringify(balancedData);
            const newBalancedJSON = JSON.stringify(newBalancedData);
            return balancedJSON === newBalancedJSON;
        };

        if (size.width >= 568) {
            let tables = document.querySelectorAll('.table__markets');
            let tablesArr = Array.from(tables);

            let tablesHeight = {
                table0: 0,
                table1: 0,
            };

            tablesArr.forEach((table, id) => {
                Array.from(table.childNodes).forEach((market) => {
                    tablesHeight['table' + id] += market.offsetHeight;
                });
            });

            if (data) {
                if (!balancedData) {
                    // ['ADR LSE', '...']
                    const firstHalfOfData = Object.keys(data).slice(
                        0,
                        Object.keys(data).length / 2
                    );
                    const secondHalfOfData = Object.keys(data).slice(
                        Object.keys(data).length / 2,
                        Object.keys(data).length
                    );
                    setBalancedData([firstHalfOfData, secondHalfOfData]);
                }

                if (tablesHeight.table0 > tablesHeight.table1 + 160) {
                    const difference = tablesHeight.table0 - tablesHeight.table1;
                    let closest = '';
                    // loop through nodes of ('.table__markets')[0] *from bottom and find closest height to this difference
                    let min = 1000;
                    Array.from(tablesArr[0].childNodes)
                        .reverse()
                        .forEach((market) => {
                            // find closest height market
                            let height = market.offsetHeight;
                            if (height < min) {
                                min = height;
                                closest = market.querySelector('.table__name-p').textContent;
                            }

                            // if (height > difference - 330 && height < difference + 330) {
                            // closest = market.querySelector(".table__name-p").textContent;
                            // }
                        });

                    if (lastClosest === closest) {
                        return;
                    } else {
                        lastClosest = closest;
                    }

                    // find node => take item.name => exclude this name from balancedData[0] => include in [1]
                    let data = [...balancedData[0]];
                    let oldIndex = balancedData[0].indexOf(closest);
                    let elem = data.splice(oldIndex, 1)[0];
                    // start animation
                    if (
                        !isBalancedDataRepeat(balancedData, [
                            data,
                            [...balancedData[1], elem],
                        ])
                    ) {
                        setBalancedData([data, [...balancedData[1], elem]]);
                    }
                } else if (tablesHeight.table1 > tablesHeight.table0 + 160) {
                    const difference = tablesHeight.table1 - tablesHeight.table0;
                    let closest = '';
                    let min = 1000;
                    // loop through nodes of ('.table__markets')[1] *from bottom and find closest height to this difference
                    Array.from(tablesArr[1].childNodes)
                        .reverse()
                        .forEach((market) => {
                            // find closest height
                            let height = market.offsetHeight;
                            if (height < min) {
                                min = height;
                                closest = market.querySelector('.table__name-p').textContent;
                            }
                            // if (height > difference - 330 && height < difference + 330) {
                            // closest = market.querySelector(".table__name-p").textContent;
                            // }
                        });

                    if (lastClosest === closest) {
                        return;
                    } else {
                        lastClosest = closest;
                    }

                    // find node => take item.name => exclude this name from balancedData[1] => include in [0]
                    let data = [...balancedData[1]];
                    let oldIndex = balancedData[1].indexOf(closest);
                    let elem = data.splice(oldIndex, 1)[0];
                    // start animation
                    if (
                        !isBalancedDataRepeat(balancedData, [
                            [...balancedData[0], elem],
                            data,
                        ])
                    ) {
                        setBalancedData([[...balancedData[0], elem], data]);
                    }
                }
            }
        } else {
            if (data) {
                const firstHalfOfData = Object.keys(data).slice(
                    0,
                    Object.keys(data).length / 2
                );
                const secondHalfOfData = Object.keys(data).slice(
                    Object.keys(data).length / 2,
                    Object.keys(data).length
                );

                if (
                    !isBalancedDataRepeat(balancedData, [
                        firstHalfOfData,
                        secondHalfOfData,
                    ])
                ) {
                    setBalancedData([firstHalfOfData, secondHalfOfData]);
                }
            }
        }
    }, [data, balancedData, size.width, tablesToShow]);

    // get mode status from localstorage
    const defaultMode = localStorage.getItem('mode') || 'one';

    // two-columns mode
    const [mode, setMode] = useState({
        status: defaultMode,
        visibility: mobileCheck(),
    });

    const changeMode = () => {
        const x = document.querySelector('#mode');
        if (mode.status !== 'two') {
            setMode({...mode, status: 'two'});
            x.setAttribute('content', 'width=device-width, initial-scale=0.5');
            localStorage.setItem('mode', 'two');
        } else {
            setMode({...mode, status: 'one'});
            x.setAttribute('content', 'width=device-width, initial-scale=1');
            localStorage.setItem('mode', 'one');
        }
    };

    useEffect(() => {
        const x = document.querySelector('#mode');
        if (window.innerHeight < window.innerWidth) {
            if (x.attributes['content'] !== 'width=device-width, initial-scale=1') {
                x.setAttribute('content', 'width=device-width, initial-scale=1');
            }
        } else if (mode.status === 'two') {
            x.setAttribute('content', 'width=device-width, initial-scale=0.5');
        }
    }, [size.width, size.height, mode.status]);

    useEffect(() => {
        setMode({...mode, visibility: mobileCheck()});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size.width]);

    return (
        <div className="main">
            <YandexAd id={1}/>
            <div className="main__input">
                <div className="main__checkbox">
                    <div className="checkbox__item">
                        <input
                            type="checkbox"
                            checked={options.favorite}
                            onChange={() =>
                                setOptions({
                                    ...options,
                                    favorite: !options.favorite,
                                })
                            }
                            className="css-checkbox"
                        />
                        <label
                            onClick={() =>
                                setOptions({
                                    ...options,
                                    favorite: !options.favorite,
                                })
                            }
                            className="main__checkbox__label"
                        >
                            {' '}
                            Избранное
                        </label>
                    </div>
                    {mode.visibility && (
                        <div id="fixed2-d" className="checkbox__item">
                            <label onClick={changeMode} className="main__checkbox__label">
                                <p className="main__checkbox__label-show">
                                    {mode.status === 'one'
                                        ? 'Уменьшить масштаб'
                                        : 'Увеличить масштаб'}
                                </p>
                            </label>
                        </div>
                    )}
                </div>
            </div>
            <div className="main__ads-table" ref={refContainer}>
                <div className="main__table">
                    <div className="table__header">
                        <p className="table__header-favorite">Избранное</p>
                        <p>Наименование</p>
                        <p className="table__header-price">
                            Последняя <br></br> цена
                        </p>
                        <p>Изменение %</p>
                        {options.maxmin && <p>Max.</p>}
                        {options.maxmin && <p>Min.</p>}
                        <p className="table__header-time">Время</p>
                    </div>
                    <div className="kek">
                        {/* {process.env.NODE_ENV === 'production' ? size.width > 1023 ? null : <iframe src="./iframes/yandexCode1.html" width="100%" height="300" frameborder="0" scrolling="no"></iframe> : null} */}
                        {size.width < 568 && (
                            <React.Fragment>
                                <div
                                    id="fixed2"
                                    className="checkbox__item main__favorite-mob-fixed"
                                >
                                    <label
                                        onClick={changeMode}
                                        className="main__checkbox__label"
                                        style={{
                                            textAlign: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            marginLeft: '0',
                                        }}
                                    >
                                        <p
                                            className="main__checkbox__label-show"
                                            style={{marginLeft: '0'}}
                                        >
                                            {mode.status === 'one'
                                                ? 'Уменьшить масштаб'
                                                : 'Увеличить масштаб'}
                                        </p>
                                    </label>
                                </div>
                                <div
                                    id="fixed1"
                                    className="checkbox__item main__favorite-mob-fixed"
                                >
                                    <input
                                        type="checkbox"
                                        checked={options.favorite}
                                        onChange={() =>
                                            setOptions({
                                                ...options,
                                                favorite: !options.favorite,
                                            })
                                        }
                                        className="css-checkbox"
                                    />
                                    <label
                                        onClick={() => FavoriteButtonHandler()}
                                        className="main__checkbox__label"
                                    >
                                        {options.favorite ? (
                                            <img alt="favorite star" src={favoriteStar}></img>
                                        ) : (
                                            <img alt="star" src={star}></img>
                                        )}
                                        <p className="main__checkbox__label-show">Избранное</p>
                                    </label>
                                </div>
                            </React.Fragment>
                        )}

                        {!options.favorite ? (
                            data &&
                            balancedData &&
                            balancedData.map((dataArr, i) => (
                                <div className="table__markets" key={i + '-market'}>
                                    {dataArr.map((item) => {
                                        return (
                                            <React.Fragment key={item}>
                                                <div className="table__market">
                                                    <div
                                                        className="table__name"
                                                        onClick={() =>
                                                            tablesToShow.includes(item)
                                                                ? setTablesToShow(
                                                                    tablesToShow.filter(
                                                                        (value) => value !== item
                                                                    )
                                                                )
                                                                : setTablesToShow([...tablesToShow, item])
                                                        }
                                                    >
                            <span
                                className={`table__name-arrow  ${
                                    tablesToShow.includes(item)
                                        ? 'table__name-arrow--up'
                                        : 'table__name-arrow--down'
                                }`}
                            >
                              ▼
                            </span>
                                                        <p className="table__name-p">{item}</p>
                                                        {item === 'ADR FSE' ? (
                                                            <>
                                <span className="table__name-currency">
                                  {!showCurrency[item] ? (
                                      <button
                                          className="currency__button--ru"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              ChangeCurrencyToRubles(item);
                                          }}
                                      >
                                          RUB
                                      </button>
                                  ) : (
                                      <button
                                          className="currency__button--ru"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              ChangeCurrencyToUsd(item);
                                          }}
                                      >
                                          EUR
                                      </button>
                                  )}
                                </span>
                                                            </>
                                                        ) : data[item]?.[0]?.toCurrency !== '' ? (
                                                            <>
                                <span className="table__name-currency">
                                  {!showCurrency[item] ? (
                                      <button
                                          className="currency__button--ru"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              ChangeCurrencyToRubles(item);
                                          }}
                                      >
                                          RUB
                                      </button>
                                  ) : (
                                      <button
                                          className="currency__button--ru"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              ChangeCurrencyToUsd(item);
                                          }}
                                      >
                                          USD
                                      </button>
                                  )}
                                </span>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    {/* use quote.strId to bind favorite quotes */}
                                                    {tablesToShow.includes(item) &&
                                                        data[item].map((quote, key) => {
                                                            return (
                                                                <div key={item + '-' + key}>
                                                                    <Quote
                                                                        quote={quote}
                                                                        showCurrency={
                                                                            showCurrency[item]
                                                                                ? showCurrency[item]
                                                                                : false
                                                                        }
                                                                        setFavorite={() =>
                                                                            favoriteHandler(item, quote, quote.strId)
                                                                        }
                                                                        isFavorite={
                                                                            favoriteObject[item] !== undefined
                                                                                ? favoriteObject[item].includes(
                                                                                    quote.strId
                                                                                )
                                                                                : false
                                                                        }
                                                                        index={key}
                                                                    ></Quote>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            ))
                        ) : Object.keys(favoriteItemsObject).length !== 0 ? (
                            [0, Object.keys(favoriteItemsObject).length / 2].map(
                                (num, dataHelperFavoriteId) => (
                                    <div
                                        className="table__markets"
                                        key={dataHelperFavoriteId + 'helper'}
                                    >
                                        {Object.keys(favoriteItemsObject)
                                            .slice(num, dataHelperFavorite[dataHelperFavoriteId + 1])
                                            .map((item, index) => {
                                                return (
                                                    <React.Fragment
                                                        key={index + 'fragm-helper' + dataHelperFavoriteId}
                                                    >
                                                        <div className="table__market">
                                                            <div
                                                                className="table__name"
                                                                key={index}
                                                                onClick={() =>
                                                                    tablesToShow.includes(item)
                                                                        ? setTablesToShow(
                                                                            tablesToShow.filter(
                                                                                (value) => value !== item
                                                                            )
                                                                        )
                                                                        : setTablesToShow([...tablesToShow, item])
                                                                }
                                                            >
                                <span
                                    className={`table__name-arrow  ${
                                        tablesToShow.includes(item)
                                            ? 'table__name-arrow--up'
                                            : 'table__name-arrow--down'
                                    }`}
                                >
                                  ▼
                                </span>
                                                                <p className="table__name-p">{item}</p>
                                                                {item === 'ADR FSE' ? (
                                                                    <>
                                    <span className="table__name-currency">
                                      {!showCurrency[item] ? (
                                          <button
                                              className="currency__button--ru"
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  ChangeCurrencyToRubles(item);
                                              }}
                                          >
                                              RUB
                                          </button>
                                      ) : (
                                          <button
                                              className="currency__button--ru"
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  ChangeCurrencyToUsd(item);
                                              }}
                                          >
                                              EUR
                                          </button>
                                      )}
                                    </span>
                                                                    </>
                                                                ) : favoriteItemsObject[item]?.[0]
                                                                    ?.toCurrency !== '' ? (
                                                                    <>
                                    <span className="table__name-currency">
                                      {!showCurrency[item] ? (
                                          <button
                                              className="currency__button--ru"
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  ChangeCurrencyToRubles(item);
                                              }}
                                          >
                                              RUB
                                          </button>
                                      ) : (
                                          <button
                                              className="currency__button--ru"
                                              onClick={(e) => {
                                                  e.stopPropagation();
                                                  ChangeCurrencyToUsd(item);
                                              }}
                                          >
                                              USD
                                          </button>
                                      )}
                                    </span>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                            {/* use quote.strId to bind favorite quotes */}
                                                            {tablesToShow.includes(item) &&
                                                                favoriteItemsObject[item].map((quote, key) => {
                                                                    return (
                                                                        <div key={key}>
                                                                            <Quote
                                                                                quote={quote}
                                                                                showCurrency={
                                                                                    showCurrency[item]
                                                                                        ? showCurrency[item]
                                                                                        : false
                                                                                }
                                                                                setFavorite={() =>
                                                                                    favoriteHandler(
                                                                                        item,
                                                                                        quote,
                                                                                        quote.strId
                                                                                    )
                                                                                }
                                                                                isFavorite={
                                                                                    favoriteObject[item] !== undefined
                                                                                        ? favoriteObject[item].includes(
                                                                                            quote.strId
                                                                                        )
                                                                                        : false
                                                                                }
                                                                                index={key}
                                                                            ></Quote>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </div>
                                )
                            )
                        ) : (
                            <div className="quotes__favorites--noone">
                                <p>У вас нет избранных котировок.</p>
                                <p>
                                    Нажмите на звездочку левее котировки, чтобы добавить её в
                                    избранные!
                                </p>
                            </div>
                        )}
                    </div>
                    <YandexAd id={2}/>
                    <News rss={rss}></News>
                </div>
            </div>
        </div>
    );
};


export default Main;
