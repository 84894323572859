import {useCallback, useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';

// Styles
import './styles/app.scss';

import Header from './components/Header';

import Main from './components/Main';
import Footer from './components/Footer';
import {Helmet} from "react-helmet";

function App() {
    const [sapeLinks, setSapeLinks] = useState({__html: ''});

    const getSapeLinks = useCallback(() => {
        if (sapeLinks.__html) {
            return;
        }

        import('./components/utils/fetchData').then(({htmlSapeLinks}) => {
            setSapeLinks({__html: htmlSapeLinks});
        });
    }, [sapeLinks.__html]);

    const [moveSpecial, setMoveSpecial] = useState(false);

    const closeGoDaddySpecial = () => {
        setMoveSpecial(false);
        window.localStorage.setItem('DOMAIN_SPECIAL', true);
    };

    return (
        <div className="App">
            <Helmet>
                {/* Код загрузчика рекламы */}
                <script>window.yaContextCb=window.yaContextCb||[]</script>
                <script src="https://yandex.ru/ads/system/context.js" async></script>
            </Helmet>
            <Header></Header>
            <div className="wrapper__main">
                {/* {process.env.NODE_ENV === 'production' ? (
          window.innerWidth < 1120 ? null : (
            <div className="desk-iframes">
              <iframe
                title="ya"
                src="./iframes/yandexDesktop.html"
                frameborder="0"
                scrolling="no"
                className="desk-iframe"
              ></iframe>
              <iframe
                title="ya"
                src="./iframes/yandexDesktop.html"
                frameborder="0"
                scrolling="no"
                className="desk-iframe"
                style={{ top: 'calc(610px + 40px * 2)' }}
              ></iframe>
              <iframe
                title="ya"
                src="./iframes/yandexDesktopSmol.html"
                frameborder="0"
                scrolling="no"
                className="desk-iframe"
                height="300"
                style={{ top: 'calc(620px * 2 + 40px * 2)' }}
              ></iframe>
            </div>
          )
        ) : (
          <div className="desk-iframes">
            <div
              style={{ backgroundColor: 'blue' }}
              className="desk-iframe"
            ></div>
            <div
              style={{ backgroundColor: 'blue', top: 'calc(610px + 40px * 2)' }}
              className="desk-iframe"
            ></div>
            <div
              style={{
                backgroundColor: 'blue',
                top: 'calc(620px * 2 + 40px * 2)',
              }}
              className="desk-iframe"
            ></div>
          </div>
        )} */}
                <ErrorBoundary
                    fallback={
                        <div style={{padding: '12px'}}>
                            Что-то пошло не так с получением данных, обновите страницу
                        </div>
                    }
                >
                    <Main getSapeLinks={getSapeLinks}></Main>
                </ErrorBoundary>
                {moveSpecial ? (
                    <>
                        <div className="GoDaddyOverlay"></div>
                        <div className="GoDaddySpecial">
                            <p>
                                Сайт переезжает на домен{' '}
                                <a
                                    href="https:\\quote-spy.online"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    https:\\quote-spy.online
                                </a>
                                , а работа по прежней ссылке не гарантируется. Обновите,
                                пожалуйста, адрес в закладках.
                            </p>
                            <button
                                className="GoDaddySpecial__close"
                                onClick={closeGoDaddySpecial}
                                aria-label="Закрыть модальное окно"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-x"
                                >
                                    <line x1="18" y1="6" x2="6" y2="18"/>
                                    <line x1="6" y1="6" x2="18" y2="18"/>
                                </svg>
                            </button>
                        </div>
                    </>
                ) : null}
            </div>
            <div className="wrapper__footer">
                <Footer></Footer>
            </div>
            <div className="wrapper__endgame">
                <hr className="endgame__hr"></hr>
                <div className="endgame__text__wrapper">
                    <p className="footer__copyright__text endgame__copyright__text">
                        Актуальные биржевые котировки онлайн, котировки фьючерсов, котировки
                        акций, облигаций, международные индексы с постоянным обновлением.
                        Онлайн курс гривны, теньге, зайчика, юаня к рублю. Рынок forex и
                        графики котировок.<br></br>
                    </p>
                </div>
                <p
                    className="footer__copyright__text endgame__copyright__text endgame__text__wrapper sape-links"
                    dangerouslySetInnerHTML={sapeLinks}
                />
            </div>
        </div>
    );
}

export default App;
