import React, {useState, useEffect, forwardRef, useRef} from 'react';
import favoriteStar from './assets/star-icon-favorite.svg';
import star from './assets/star-icon.svg';
import dots from './assets/dots.png';
import {useWindowSize} from './hooks/windowSize';
import YandexAd from "./YandexAd";

const FAVORITE_NEWS_LS = 'favoriteNewsStarsLS';

const formatTime = (time) => {
    return time.toString().length > 1 ? time : '0' + time;
};

const NewsMain = forwardRef(({ newsIndex, items, title }, ref) => {
    const counterRef = useRef(3); // Initialize the counter with 3

    return (
        <>
            <div className="main__news-table" ref={ref}>
                <div className="main__news-table-title">
                    <h2>{title}</h2>
                </div>
                <div className="main__news__articles">
                    {items &&
                        items.slice(0, 15).map((item, index) => {
                            let newsTime = new Date(item.Time.toString());
                            let day = formatTime(newsTime.getDate());
                            let month =
                                (newsTime.getMonth() + 1).toString().length > 1
                                    ? newsTime.getMonth() + 1
                                    : '0' + (newsTime.getMonth() + 1);
                            let hours = formatTime(newsTime.getHours());
                            let minutes = formatTime(newsTime.getMinutes());
                            let formattedTime = `${hours}:${minutes} (${day}.${month})`;
                            return (
                                <div className="main__news__article" key={index}>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="main__news__article-link"
                                        href={item.Link}
                                    >
                                        <div
                                            className="main__news__article-preview"
                                            dangerouslySetInnerHTML={{
                                                __html: item.Description,
                                            }}
                                        ></div>
                                    </a>
                                    <div className="main__news__article-time">
                                        {formattedTime}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
});


const News = (props) => {
    const newsByCategories = props?.rss?.reduce((acc, source) => {
        let {Name, Items, Category} = source;
        return {
            ...acc,
            [Category]: [...(acc[Category] || []), {name: Name, items: Items}],
        };
    }, {});

    const size = useWindowSize();
    const [newsButtons, showNewsButtons] = useState(false);
    const showButtons = () => {
        showNewsButtons(!newsButtons);
    };

    const [activeButton, setActiveButton] = useState('');

    useEffect(() => {
        if (!activeButton && newsByCategories) {
            setActiveButton(Object.keys(newsByCategories)?.[0]);
        }
    }, [newsByCategories, activeButton]);

    const [hideMenu, setHideMenu] = useState(false);
    const activateButton = (category) => {
        const alreadyActivated = activeButton.includes(category);
        if (alreadyActivated) {
            setHideMenu(!hideMenu);
        } else {
            setHideMenu(false);
            setActiveButton(category);
        }
    };

    const favNewsStarsStorage = JSON.parse(
        localStorage.getItem(FAVORITE_NEWS_LS)
    );
    const [favoriteNewsStars, setFavoriteNewsStars] =
        useState(favNewsStarsStorage);

    useEffect(() => {
        if (!favoriteNewsStars && newsByCategories) {
            const defaultCategory = Object.keys(newsByCategories)?.[0];
            const defaultName = newsByCategories?.[defaultCategory]?.[0]?.name;
            const defaultNewsStorage = `${defaultCategory}:${defaultName}`;
            return setFavoriteNewsStars([defaultNewsStorage]);
        }
    }, [newsByCategories, favoriteNewsStars]);

    const newsRef = React.useRef();
    const addFavorite = (category, name) => {
        setFavoriteNewsStars([...favoriteNewsStars, `${category}:${name}`]);
        Promise.resolve().then(() => {
            if (newsRef.current) {
                newsRef.current.scrollIntoView({behavior: 'smooth'});
            }
        });
    };
    const deleteFavorite = (category, name) => {
        setFavoriteNewsStars(
            favoriteNewsStars.filter((fav) => fav !== `${category}:${name}`)
        );
    };
    useEffect(() => {
        if (favoriteNewsStars) {
            localStorage.setItem(FAVORITE_NEWS_LS, JSON.stringify(favoriteNewsStars));
        }
    }, [favoriteNewsStars]);

    return (
        <div className="main__news">
            <button
                className="main__button__item main__button-mobile"
                onClick={showButtons}
            >
                <p>Список новостей</p>
                <img className="main__button__img-dots" alt="dots menu" src={dots}></img>
            </button>
            <div className={newsButtons ? `main__news__buttons` : `main__news__buttons--disabled`}>
                {newsByCategories
                    ? Object.keys(newsByCategories).map((category) => {
                        const categorySources = newsByCategories[category];
                        return (
                            <React.Fragment key={category}>
                                <button
                                    onClick={() => activateButton(category)}
                                    className={
                                        activeButton.includes(category) || size.width < 768
                                            ? `main__news__button main__news__button--active`
                                            : `main__news__button`
                                    }
                                >
                                    {category}
                                </button>
                                <div
                                    className={`main__news__buttons-subs ${
                                        activeButton.includes(category) ? '--active' : ''
                                    } ${hideMenu ? '--hide' : ''}`}
                                >
                                    {(activeButton.includes(category) || size.width < 768) &&
                                        categorySources.map(({name}, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="main__news__buttons-sub"
                                                    onClick={
                                                        favoriteNewsStars &&
                                                        Object.values(favoriteNewsStars).includes(
                                                            `${category}:${name}`
                                                        )
                                                            ? () => deleteFavorite(category, name)
                                                            : () => addFavorite(category, name)
                                                    }
                                                >
                                                    <p className="main__news-sub-star">
                                                        {favoriteNewsStars &&
                                                        Object.values(favoriteNewsStars).includes(
                                                            `${category}:${name}`
                                                        ) ? (
                                                            <img
                                                                src={favoriteStar}
                                                                alt="favorite star"
                                                            ></img>
                                                        ) : (
                                                            <img src={star} alt="star"></img>
                                                        )}
                                                    </p>
                                                    <p className="main__news-sub-title">{name}</p>
                                                </div>
                                            );
                                        })}
                                </div>
                            </React.Fragment>
                        );
                    })
                    : null}
            </div>
            <div className="main__news-tables">
                {favoriteNewsStars
                    ? favoriteNewsStars.map((item, index) => {
                        const a = item.split(':');
                        const category = a.shift();
                        const name = a.join(':');

                        const sourceItems = newsByCategories?.[category]?.find(
                            (v) => v.name === name
                        )?.items;

                        return (
                            <React.Fragment key={index}>
                                {props.rss && sourceItems && (
                                    <NewsMain newsIndex={index} items={sourceItems} title={name} ref={newsRef}/>
                                )}
                            </React.Fragment>
                        );
                    })
                    : null}
            </div>
        </div>

    );
};

export default News;
