import React, { useState, useEffect, useRef } from 'react';
import star from './assets/star-icon.svg';
import favoriteStar from './assets/star-icon-favorite.svg';
import { useWindowSize } from './hooks/windowSize';

String.prototype.spaces = function splitAllNumbersWithSpacesInString() {
  let str = this.valueOf();
  str = str.replace(/(\d)(?=(?:\d\d\d)+(?:\D|$))/g, '$1 ');
  return (function delSpaces(str) {
    var strNew = str.replace(/(\,\d+)\s(\d+)/g, '$1$2');
    return strNew === str ? strNew : delSpaces(strNew);
  })(str);
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Quote = (props) => {
  const size = useWindowSize();
  //Time and date formatting
  let dateAndTime = props.quote.time.split(' ');
  let date = dateAndTime[0];
  let helpArr = date.split('.');
  helpArr.pop();
  let dateFormatted = helpArr.join('.');
  let time =
    dateAndTime[1][1] === ':'
      ? dateAndTime[1].slice(0, 4)
      : dateAndTime[1].slice(0, 5);

  const prevCalc = usePrevious(props.quote.currentValue);

  const [isLastGreater, setIsLastGreater] = useState(0);
  useEffect(() => {
    if (prevCalc) {
      const x =
        Number.parseFloat(props.quote.currentValue.replace(',', '.')) -
        Number.parseFloat(prevCalc.replace(',', '.'));
      setIsLastGreater(x);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.quote.currentValue]);

  const quoteInCurrency = Number.parseFloat(
    (
      Number.parseFloat(props.quote.currentValue.replace(',', '.')) *
      Number.parseFloat(props.quote.toCurrency.replace(',', '.'))
    ).toFixed(2)
  );

  const quoteInCurrencyFormatted = Number.isNaN(quoteInCurrency)
    ? '—'
    : quoteInCurrency.toPrecision().replace('.', ',').spaces();

  const defaultQuote = Number.parseFloat(
    props.quote.currentValue.replace(',', '.')
  );
  const defaultQuoteFormatted = defaultQuote
    .toPrecision()
    .replace('.', ',')
    .spaces();

  return (
    <div
      className={
        props.index % 2 === 1
          ? `table__item table__item-anime-${props.index + 1}`
          : `table__item--grey table__item-anime-${props.index + 1}`
      }
    >
      <p className="table__item-star">
        {props.isFavorite ? (
          <img
            src={favoriteStar}
            alt="favorite star"
            onClick={props.setFavorite}
          ></img>
        ) : (
          <img src={star} alt="star" onClick={props.setFavorite}></img>
        )}
      </p>
      <div className="table__item-name">
        {props.quote.link !== '' ? (
          <a href={`${props.quote.link}`}>{props.quote.name}</a>
        ) : (
          <>{props.quote.name}</>
        )}
        <div className="table__item-time-mobile">
          {time}
          <br></br>
          {date}
        </div>
      </div>
      <div
        className={`table__item-price ${
          isLastGreater > 0
            ? `table__item-price--up`
            : isLastGreater === 0
            ? ``
            : `table__item-price--down`
        }`}
        style={
          size.width < 568 &&
          Number.parseFloat(props.quote.currentValue).toFixed(2).length > 8
            ? { fontSize: '14px' }
            : {}
        }
      >
        <span>
          {props.showCurrency
            ? quoteInCurrencyFormatted
            : defaultQuoteFormatted}
        </span>
        <div
          className={`table__item-change-mob ${
            Number.parseFloat(props.quote.change.replace(',', '.')) < 0
              ? ` table__item-change-mobile`
              : Number.parseFloat(props.quote.change.replace(',', '.')) === 100
              ? ``
              : `table__item-change--up-mobile`
          }`}
        >
          {props.quote.change === '100' ? `—` : `${props.quote.change}%`}
        </div>
      </div>
      <p
        className={
          Number.parseFloat(props.quote.change.replace(',', '.')) < 0
            ? `table__item-change`
            : `table__item-change--up`
        }
      >
        {props.quote.change === '100' ? `—` : `${props.quote.change}%`}
      </p>
      <div
        className="table__item-max"
        style={
          size.width < 568 && props.quote.maxValue.length > 9
            ? { fontSize: '12px' }
            : {}
        }
      >
        {props.showCurrency
          ? Number.parseFloat(
              (
                Number.parseFloat(props.quote.toCurrency.replace(',', '.')) *
                Number.parseFloat(props.quote.maxValue.replace(',', '.'))
              ).toFixed(2)
            )
              .toPrecision()
              .replace('.', ',')
          : Number.parseFloat(props.quote.maxValue.replace(',', '.'))
              .toPrecision()
              .replace('.', ',')}
        <div className="table__item-min-mobile">
          {props.showCurrency
            ? Number.parseFloat(
                (
                  Number.parseFloat(props.quote.toCurrency.replace(',', '.')) *
                  Number.parseFloat(props.quote.minValue.replace(',', '.'))
                ).toFixed(2)
              )
                .toPrecision()
                .replace('.', ',')
            : Number.parseFloat(props.quote.minValue.replace(',', '.'))
                .toPrecision()
                .replace('.', ',')}
        </div>
      </div>
      <p className="table__item-min">
        {Number.parseFloat(props.quote.minValue.replace(',', '.'))
          .toPrecision()
          .replace('.', ',')}
      </p>
      <p className="table__item-time">
        {time} ({dateFormatted})
      </p>
    </div>
  );
};

export default Quote;
