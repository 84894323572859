import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

window.__forceSmoothScrollPolyfill__ = true;


ReactDOM.render(<App />, document.getElementById("root"));