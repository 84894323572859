import React, { useState } from 'react';
import logo from './assets/logo.png';
import menu from './assets/menu.svg';

function Header() {
    const [headerButtons, showHeaderButtons] = useState(false);

    const toggleNavigation = () => {
        showHeaderButtons(!headerButtons);
    };

    return (
        <div className="header">
            <div className="header-container">
                <div className="logo-container">
                    <a className="logo-url" href="https://quote-spy.online/">
                        <img className="logo" src="https://quote-spy.online/analytics/resources/logo.svg"
                             alt="QuoteSpy logo" />
                        <h1>QuoteSpy</h1>
                    </a>
                    <div className="expand-button" onClick={toggleNavigation}>
                        <img src="/analytics/resources/icons/ic_expand_24x24.svg" alt="Expand menu" />
                    </div>
                </div>
                <nav id="navigation" style={{ maxHeight: headerButtons ? '500px' : '' }}>
                    <a href="/?mode=full" className="selected">Котировки</a>
                    <div className="hidden-in-mobile" style={{ marginLeft: '0vw' }}>(</div>
                    <a href="/?mode=full">Web</a>
                    <div className="hidden-in-mobile">|</div>
                    <a href="/default.aspx?mode=full">Desktop</a>
                    <div className="hidden-in-mobile" style={{ marginRight: '0vw' }}> )</div>
                    <a href="/analytics/articles">AI Аналитика</a>
                    <a href="/analytics/currencies">AI Сигналы</a>
                    <a href="/analytics/histdata">Экспорт</a>
                </nav>
            </div>
        </div>
    );
}

export default Header;
