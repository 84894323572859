/* eslint-disable no-undef */
async function windows1251ResponseToUTF8Response(response) {
  return new Response(
    new TextDecoder('windows-1251').decode(await response.arrayBuffer())
  );
}

const BASE_HOST =
  process?.env?.NODE_ENV === 'development'
    ? process.env.REACT_APP_PARSING_SERVER
    : window.location.origin;

/**
 * @GET OBJECT WITH TYPES
 * FOR EXAMPLE: ADR FSE, CRYPTO
 * EVERY TYPE IS AN OBJECT THAT CONTAINS ARRAY OF COMPANIES/CURRENCIES
 * EVERY ITEM IN ARRAY CONTAIN NAME, TIME, VALUES
 */
let extension = process?.env?.NODE_ENV === 'development' ? '' : '.aspx';

let quoteUrl = `${BASE_HOST}/QuoteInfo${extension}`;

let objArr = [];

const myHeaders = new Headers();
myHeaders.append('Pragma', 'no-cache');
myHeaders.append('Cache-Control', 'no-store');

const myInit = {
  method: 'GET',
  headers: myHeaders,
};

const fetchQuotes = async (fn, errH) => {
  const ms = Date.now();
  let data = await fetch(quoteUrl + '?timestamp=' + ms, myInit);
  let utf8Document = await windows1251ResponseToUTF8Response(data);
  let html = await utf8Document.text();

  let parser = new DOMParser();
  let document = parser.parseFromString(html, 'text/html');

  let quoteData = document.body.innerHTML;

  let quotes = quoteData.split('<br>');
  let globalObj = {};
  quotes.forEach((message) => {
    let kek = message.replace('&amp;', '&').split(';');
    if (kek.length > 2) {
      if (Object.keys(globalObj).includes(kek[2])) {
        objArr.push({
          strId: kek[0],
          name: kek[1],
          time: kek[3],
          currentValue: kek[4],
          maxValue: kek[6],
          minValue: kek[7],
          change: kek[8],
          link: kek[10],
          toCurrency: kek[11],
        });
        globalObj[kek[2]] = objArr;
      } else {
        objArr = [];

        objArr.push({
          strId: kek[0],
          name: kek[1],
          type: kek[2],
          time: kek[3],
          currentValue: kek[4],
          maxValue: kek[6],
          minValue: kek[7],
          change: kek[8],
          link: kek[10],
          toCurrency: kek[11],
        });
        globalObj[kek[2]] = objArr;
      }
    }
  });

  const res = JSON.stringify(globalObj);
  fn(res);
};

// https://quote-spy.{com|online}/allinfo.aspx
let newsUrl = `${BASE_HOST}/allinfo${extension}`;

let htmlSapeLinks;

const fetchNewsAndQuotes = async (fn1, errH) => {
  const ms = Date.now();
  let data = await fetch(newsUrl + '?timestamp=' + ms, myInit);
  let html;

  let utf8Document = await windows1251ResponseToUTF8Response(data);
  html = await utf8Document.text();

  let parser = new DOMParser();
  let document = parser.parseFromString(html, 'text/html');

  let domBody = document.body.innerHTML;

  let splitDomBody = domBody.split(',"SapeLinks"');

  const finalDomBody = splitDomBody[0] + '}';
  htmlSapeLinks = splitDomBody[1].slice(2, -2);
  htmlSapeLinks = htmlSapeLinks.replace(/\\|&quot;/g, '');

  let rssData = JSON.parse(finalDomBody)['Channels'];
  fn1(rssData);
};

export { fetchQuotes, fetchNewsAndQuotes, htmlSapeLinks };
