import React from 'react';
import logo from './assets/logo.png';
import adControl from './assets/adControl.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__info">
        <div className="footer__logo">
          <img
            className="footer__logo__img"
            alt="Quote Spy logo"
            src={logo}
          ></img>
        </div>
        <div className="footer__links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
            href="https://quote-spy.online?mode=full"
          >
            Полная версия сайта
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.analytic-spy.com/"
            className="footer__link"
          >
            Биржа аналитики
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:quotespy.com@gmail.com"
            className="footer__link"
          >
            Контакты
          </a>
        </div>
        <div className="footer__links-source">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.batstrading.com/data"
            className="footer__link footer__link-source"
          >
            Источники <br></br>BATS Market Data <br></br>{' '}
            <img src={adControl}></img>
          </a>
        </div>
      </div>
      <div className="footer__copyright">
        <p className="footer__copyright__text">
          Информация на данном сайте предоставляется "КАК ЕСТЬ" и предназначена
          только для ознакомительных целей без каких-либо гарантий и передачи
          прав. Любое использование информации в иных целях запрещено и
          производится на ваш страх и риск.<br></br> Все права защищены.
          Copyright © 2007{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://quote-spy.online?mode=full"
          >
            QUOTE-SPY.ONLINE
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
